import React, {Component} from "react";
import {MDBNavbar, MDBNavbarBrand} from "mdbreact";

class NavbarPage extends Component {
    render() {
        return (
            <MDBNavbar color="green" dark expand="md" style={{marginTop: "20px"}}>
                <MDBNavbarBrand>
                    <strong className="white-text">Worktime calculator</strong>
                </MDBNavbarBrand>
            </MDBNavbar>
        );
    }
}

export default NavbarPage;
