import React, {Component} from "react";
import {MDBContainer, MDBRow, MDBCol} from "mdbreact";
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import NumericInput from 'react-numeric-input';
import '../css/Home.css';
import ClockAnalog from "react-clock";
import ClockDig from "react-live-clock";

function onChangeTime(test) {
    const startTime = document.getElementById('startTimePicker').value;
    const demandedWorktime = document.getElementById('worktime').value;
    const lunchStartTime = document.getElementById('lunchStartTimePicker').value;
    const lunchEndTime = document.getElementById('lunchEndTimePicker').value;
    var lunchTime = moment();
    if (lunchStartTime === "" || lunchEndTime === "") {
        lunchTime.seconds(0);
        lunchTime.minutes(0);
        lunchTime.hours(0);
    } else {
        lunchTime = calcLunchTimeSpan(lunchStartTime, lunchEndTime);
    }
    var targetTime = calcTargetTime(startTime, demandedWorktime, lunchTime);
    const lunchStartMoment = getMoment(lunchStartTime);
    const lunchEndMoment = getMoment(lunchEndTime);
    calcDiff(targetTime);
    if (demandedWorktime > 0) {
        if (targetTime.minute() > 10) {
            document.getElementById('endtime').innerHTML = "You have to work until " + targetTime.hour() + ":" + targetTime.minute() ;
        } else {
            document.getElementById('endtime').innerHTML = "You have to work until " + targetTime.hour() + ":0" + targetTime.minute() + " Uhr arbeiten.";
        }
    } else {
        document.getElementById('endtime').innerHTML = "You can't work 0 hours.";
    }
    if (targetTime.isBefore(lunchStartMoment) || lunchEndMoment.isBefore(lunchStartMoment)) {
        document.getElementById('comparisonTime').innerHTML = "The entered lunch break cannot be correct";
    }
}

function calcDiff(endTime) {
    var calcDiffTime = moment();
    calcDiffTime.hour(endTime.hour());
    calcDiffTime.minutes(endTime.minutes());

    if (endTime.isAfter(moment())) { // normal calculation
        calcDiffTime.subtract(moment().hour(), 'hours').subtract(moment().minutes(), 'minutes');
        document.getElementById('comparisonTime').innerHTML = "These are " + calcDiffTime.hour() + " hours and " + calcDiffTime.minute() + " minutes. ( " + industrialHour(calcDiffTime.hour(), calcDiffTime.minutes()) + ")";
    }
    if (endTime.isBefore(moment())) {
        calcDiffTime = moment();
        calcDiffTime.subtract(endTime.hour(), 'hours');
        calcDiffTime.subtract(endTime.minutes(), 'minutes');
        document.getElementById('comparisonTime').innerHTML = "You do " + industrialHour(calcDiffTime.hour(), calcDiffTime.minutes()) + " overtime. (" + calcDiffTime.hour() + ":" + calcDiffTime.minutes() + ")";
    }
}


function industrialHour(hour, minute) {
    var time = 0.0;
    time = hour + (minute / 60);
    return time.toFixed(2);
}

function getMoment(start) {
    var targetTime = moment();
    var splitStart = start.split(':');
    targetTime.hour(splitStart[0]);
    targetTime.minute(splitStart[1]);
    return targetTime
}

function calcLunchTimeSpan(start, end) {
    var targetTime = moment();
    var splitStart = start.split(':');
    var splitEnd = end.split(':');
    targetTime.hour(splitEnd[0]);
    targetTime.minute(splitEnd[1]);
    targetTime.subtract(splitStart[0], 'hours');
    targetTime.subtract(splitStart[1], 'minutes');
    return targetTime
}

function calcTargetTime(startTime, worktime, lunchTime) {
    var targetTime = moment();
    var splitStart = startTime.split(':');
    targetTime.hour(splitStart[0]);
    targetTime.minute(splitStart[1]);
    targetTime.add(worktime, 'hours');
    targetTime.add(lunchTime.hour(), 'hours');
    targetTime.add(lunchTime.minutes(), 'minutes');
    return targetTime;
}

function setStart() {
    var theTest = moment();
    theTest.hour(7);
    theTest.minute(0);
    return theTest;
}

function setLunchStart() {
    var theTest = moment();
    theTest.hour(12);
    theTest.minute(30);
    return theTest;
}

function setLunchEnd() {
    var theTest = moment();
    theTest.hour(13);
    theTest.minute(0);
    return theTest;
}

class Home extends Component {
    state = {
        date: new Date(),
        worktime: 8,
        endtime: "15:00",

    };

    componentDidMount() {
        setInterval(
            () => this.setState({date: new Date(), worktime: document.getElementById('worktime').value}),
            1000
        );
    }

    render() {
        return (
            <MDBContainer>
                <MDBContainer>
                    <MDBRow>
                        <MDBCol/>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size="5">
                            <label>When did you start working?</label>
                            <br/>
                            <TimePicker id="startTimePicker" required={true} defaultValue={setStart()}
                                        showSecond={false} onClose={onChangeTime}/>
                            <br/>
                            <br/>
                            <label>How long do you want to work?</label>
                            <br/>
                            <NumericInput step={0.25} id="worktime" onChange={onChangeTime} size={3}
                                          min={0} max={10}
                                          value={this.state.worktime}/>
                            <br/>
                            <br/>
                            <label>When did you start your lunch break?</label>
                            <br/>
                            <TimePicker id="lunchStartTimePicker" required={true} defaultValue={setLunchStart()}
                                        showSecond={false} onClose={onChangeTime}/>
                            <br/>
                            <br/>
                            <label>When did you finish your lunch break?</label>
                            <br/>
                            <TimePicker id="lunchEndTimePicker" required={true} defaultValue={setLunchEnd()}
                                        showSecond={false} onClose={onChangeTime}/>
                            <br/>
                            <br/>
                            <div id="endtime">You have to work until 3:30 </div>
                            <br/>
                            <div id="comparisonTime"></div>
                            <div id="comparisonTime2"></div>
                        </MDBCol>
                        <MDBCol size="4">
                            <p id="padleft6"><ClockAnalog value={this.state.date}/></p>
                            <p id="textal"><ClockDig format={'HH:mm:ss'} ticking={true} timezone={'Europe/Berlin'}/></p>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBContainer>
        );
    }
}

export default Home;
