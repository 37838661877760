import React, {Component} from 'react';
import NavbarPage from './components/js/NavbarPage'
import Home from './components/js/Home'
import {MDBContainer} from 'mdbreact';


class App extends Component {
    render() {
        return (
            <div className="App">
                <MDBContainer>
                    <NavbarPage/>
                    <Home/>
                </MDBContainer>
            </div>
        );
    }
}

export default App;
